import { ApiErrors } from 'src/enums';

export const apiErrorStatusMessage = (status?: string | null): string => {
  if (!status) {
    return ApiErrors.UNKNOWN;
  }

  const message = ApiErrors[status];

  if (!message) {
    return ApiErrors.UNKNOWN;
  }

  return message;
};
