import { Component } from '@angular/core';
import { translations } from './header.translations';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/modules/auth/auth.service';

@Component({
  selector: 'app-root-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  translations = translations;

  items: MenuItem[] = [
    {
      label: translations.documentsMenu,
      routerLink: '/translate/documents',
    },
    {
      label: translations.textMenu,
      routerLink: '/translate/text',
    },
  ];

  constructor(private readonly authService: AuthService) {}

  logout() {
    this.authService.logout();
  }
}
