import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {
  FileTranslationOutput,
  FileTranslationResult,
  TextTranslationOutput,
  TranslationWebhookResponse,
} from '../interfaces';
import {
  FileTranslationRequest,
  TextTranslationRequest,
} from '../interfaces/TranslationRequest.interface';
import {
  Observable,
  catchError,
  concat,
  concatMap,
  delay,
  filter,
  first,
  map,
  merge,
  mergeMap,
  of,
  repeat,
} from 'rxjs';
import { getFileSizeFromBase64, replaceUnicode, splitToChunks } from '../utils';
import { apiErrorStatusMessage } from 'src/utils/apiErrorStatusMessage';
import { environment } from 'src/environments/environment';

@Injectable()
export class TranslationService {
  protected readonly timeoutText: number = environment.timeoutTextSeconds;

  protected readonly timeoutFiles: number = environment.timeoutFilesSeconds;

  protected readonly filesChunks: number = environment.parallelFilesLimit;

  protected readonly bigFilesChunks: number =  environment.parallelBigFilesLimit;

  constructor(private readonly apiService: ApiService) {}

  public translateRaw(
    data: TextTranslationRequest | FileTranslationRequest,
    timeoutSeconds: number, domain?: string, model?: string
  ): Observable<TranslationWebhookResponse> {
    return this.apiService
      .requestTranslation(data, domain, model)
      .pipe(
        first(),
        delay(timeoutSeconds * 1000) // Delay before check request
      )
      .pipe(
        mergeMap((orchestratorResult) =>
          this.apiService
            .checkTranslation(orchestratorResult.statusQueryGetUri)
            .pipe(
              repeat({ delay: timeoutSeconds * 1000 }),
              filter((res) =>
                ['Completed', 'Failed'].includes(res.runtimeStatus)
              ),
              first(),
              map((res) => {
                if (res.runtimeStatus === 'Completed') {
                  return res;
                }

                throw new Error(apiErrorStatusMessage(res.customStatus));
              })
            )
        )
      );
  }

  public translateText(data: TextTranslationRequest, domain?: string, model?: string): Observable<string> {
    data.type = 'text';

    return this.translateRaw(data, this.timeoutText, domain, model).pipe(
      map((result) => {
        if (
          !result.output ||
          !Array.isArray(result.output) ||
          result.output.length === 0 ||
          typeof result.output[0] !== 'string'
        ) {
          throw new Error('Result is empty or invalid');
        }

        const parsedOutput = JSON.parse(
          result.output[0]
        ) as TextTranslationOutput;

        return replaceUnicode(parsedOutput.translated_text);
      })
    );
  }

  public translateFiles(
    data: FileTranslationRequest,
    domain?: string,
    model?: string
  ): Observable<FileTranslationResult> {
    data.type = 'files';

    const translationRequests: FileTranslationRequest[] = data.files
      .map((file) =>
        data.languages.map(
          (language): FileTranslationRequest => ({
            files: [file],
            languages: [language],
            type: 'files',
          })
        )
      )
      .flat();

    /**
     * This logic splits requests array into chunks
     * and runs requests for every chunk in parallel.
     * Chunks are running sequentially.
     *
     *    Chunk1: [request | request | request]
     *                         V
     *                  Chunk1 is done
     *                         V
     *    Chunk2: [request | request | request]
     */
    
    const translationRequestChunks = this.splitRequestsIntoChunks(translationRequests);

    const translationObservableChunks = translationRequestChunks.map(
      (translationChunk) =>
        translationChunk.map(
          (request): Observable<FileTranslationResult> =>
            this.translateRaw(request, this.timeoutFiles, domain, model).pipe(
              map((result) => {
                if (
                  !result.output ||
                  !Array.isArray(result.output) ||
                  result.output.length === 0 ||
                  typeof result.output[0] !== 'string'
                ) {
                  throw new Error('Result is empty or invalid');
                }

                const parsedOutput = JSON.parse(
                  result.output[0]
                ) as FileTranslationOutput;

                const languageOutput = Object.values(parsedOutput.results)[0];
                const translation = Object.values(languageOutput)[0];

                return {
                  translationBase64: translation,
                  fileSizeBytes: getFileSizeFromBase64(translation.length),
                  fileName: request.files[0].name,
                  language: request.languages[0],
                };
              }),
              catchError((error) =>
                of({
                  error,
                  fileSizeBytes: 0,
                  fileName: request.files[0].name,
                  language: request.languages[0],
                })
              )
            )
        )
    );

    return concat(translationObservableChunks).pipe(
      concatMap((translationObservableChunk) =>
        merge(...translationObservableChunk)
      )
    );
  }

  private splitRequestsIntoChunks(translationRequests: FileTranslationRequest[]) {
    const smallFileRequests = translationRequests.filter(req => req.files.every(file => file.size <= 1 * 1024 * 1024));
    const bigFileRequests = translationRequests.filter(req => req.files.some(file => file.size > 1 * 1024 * 1024));

    const smallFileChunks = splitToChunks(smallFileRequests, this.filesChunks);
    const bigFileChunks = splitToChunks(bigFileRequests, this.bigFilesChunks);

    return [...smallFileChunks, ...bigFileChunks];
  }
}
