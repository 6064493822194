import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SharedLoaderService } from './sharedLoader.service';

@Injectable()
export class LoaderService {
  public readonly loaderProgress = new BehaviorSubject<{
    current: number;
    steps: number;
  } | null>(null);

  constructor(private sharedLoaderService: SharedLoaderService) {}

  disable() {
    this.loaderProgress.next(null);
  }

  incProgress(inc = 1) {
    const value = this.loaderProgress.getValue();

    if (!value) {
      return;
    }

    if (value.current === value.steps - inc) {
      this.disable();
      return;
    }

    this.loaderProgress.next({
      current: value.current + inc,
      steps: value.steps,
    });
  }

  setProgressValue(newValue: number) {
    const value = this.loaderProgress.getValue();

    if (value.current !== newValue) {
      this.loaderProgress.next({
        current: newValue,
        steps: value.steps,
      });
    }
  }

  startProgress(steps: number, current = 0) {
    this.sharedLoaderService.setAmount(steps)
    this.loaderProgress.next({ current, steps });
  }

  getAmount(): number {
    return this.sharedLoaderService.getAmount();
  }
}
