export const environment = {
  production: process.env['ENV_NAME'] === 'production',
  auth: {
    clientId: process.env['AZURE_CLIENT_ID'],
    authority: process.env['AZURE_AUTHORITY'],
    redirectUrl: process.env['AZURE_REDIRECT_URL'],
  },
  apiUrl: process.env['API_URL'],
  timeoutTokenRefreshMinutes: parseInt(
    process.env['TIMEOUT_TOKEN_FORCE_REFRESH']
  ),
  timeoutTextSeconds: parseInt(process.env['TIMEOUT_TEXT']),
  timeoutFilesSeconds: parseInt(process.env['TIMEOUT_FILES']),
  fileSizeLimitMB: parseInt(process.env['FILE_SIZE_LIMIT_MB']),
  parallelFilesLimit: parseInt(process.env['PARALLEL_FILES_TRANSLATION_LIMIT']),
  parallelBigFilesLimit: parseInt(process.env['PARALLEL_BIG_FILES_TRANSLATION_LIMIT']),
  domains: process.env['DOMAINS'] ? process.env['DOMAINS'].split(',') : [],
  models: process.env['MODELS'] ? process.env['MODELS'].split(',') : [],
  fileTypes: process.env['FILE_TYPES'] ? process.env['FILE_TYPES'].split(',') : [],
  customMessage: process.env['CUSTOM_MESSAGE']
};
