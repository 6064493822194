import { LanguageCode } from 'src/types';
import { getFileExtension } from './getFileExtension';
import { getFileName } from './getFileName';
import { checkBase64Docx } from './checkBase64Docx';

export const composeDownloadedFileName = (
  name: string,
  language: LanguageCode,
  base64?: string
) => {
  const originalFileExt = getFileExtension(name);
  const fileExt = originalFileExt === 'pdf' && checkBase64Docx(base64) ? 'docx' : originalFileExt;
  const fileName = getFileName(name);
  return `${fileName}_${language}.${fileExt}`;
};