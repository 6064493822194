<div
  *ngIf="authService.isUserLoggedIn | async"
  class="flex flex-column min-h-full">
  <app-root-header></app-root-header>
  <p-divider></p-divider>
  <app-root-body></app-root-body>
  <app-root-footer></app-root-footer>
</div>

<app-root-progress-bar></app-root-progress-bar>
