import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', redirectTo: 'translate', pathMatch: 'full' },
  {
    path: 'translate',
    loadChildren: () =>
      import('./modules/translate/translate.module').then(
        (m) => m.TranslateModule
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'download',
    loadChildren: () =>
      import('./modules/translate-download/translate-download.module').then(
        (m) => m.TranslateDownloadModule
      ),
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
