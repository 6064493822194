import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressBarModule } from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { AuthModule } from './modules/auth/auth.module';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { BodyComponent } from './components/body/body.component';
import { HeaderComponent } from './components/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { DividerModule } from 'primeng/divider';
import { TranslationService } from 'src/services/translation.service';
import { LoaderService } from 'src/services/loader.service';
import { SharedLoaderService } from 'src/services/sharedLoader.service';

@NgModule({
  declarations: [
    AppComponent,
    BodyComponent,
    FooterComponent,
    HeaderComponent,
    ProgressBarComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ButtonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ProgressBarModule,
    AuthModule,
    MenuModule,
    MenubarModule,
    DividerModule,
  ],
  providers: [TranslationService, LoaderService, SharedLoaderService],
  bootstrap: [AppComponent],
})
export class AppModule {}
