import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { BehaviorSubject, first, filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  private readonly timeoutTokenRefresh: number =
    environment.timeoutTokenRefreshMinutes * 60 * 1000;

  private refreshAuthIntervalId: number | null = null;

  public readonly authData = new BehaviorSubject<AuthenticationResult | null>(
    null
  );

  public readonly isUserLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService
  ) {
    this.msalService.initialize().subscribe(() => {
      this.handleAuth();
    });

    this.broadcastService.msalSubject$.subscribe({
      next: (event) => {
        if (event.eventType === 'msal:loginSuccess') {
          this.handleAuth();
        }
      },
    });

    this.authData
      .pipe(
        filter((data) => data !== null),
        first()
      )
      .subscribe(() => {
        if (this.refreshAuthIntervalId !== null) {
          window.clearInterval(this.refreshAuthIntervalId);
          this.refreshAuthIntervalId = null;
        }

        this.refreshAuthIntervalId = window.setInterval(
          () => this.updateAuthData(true),
          this.timeoutTokenRefresh
        );
      });
  }

  public logout() {
    this.msalService.logout();
  }

  protected handleAuth(): void {
    this.setActiveAccount();
    if (!!this.msalService.instance.getActiveAccount()) {
      this.updateAuthData();
    }
  }

  protected async updateAuthData(forceRefresh: boolean = false): Promise<void> {
    return this.msalService.instance
      .acquireTokenSilent({
        scopes: ['user.read'],
        account: this.msalService.instance.getActiveAccount(),
        forceRefresh,
      })
      .then((result) => {
        this.authData.next(result);
        this.isUserLoggedIn.next(!!result);
      })
      .catch((error) => {
        console.error(error);
        if (!forceRefresh) {
          this.updateAuthData(true); // Try to force update token
        } else {
          this.logout();
        }
      });
  }

  protected setActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount) {
      if (this.msalService.instance.getAllAccounts().length > 0) {
        const accounts = this.msalService.instance.getAllAccounts();
        this.msalService.instance.setActiveAccount(accounts[0]);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }
}
