import { Component } from '@angular/core';
import { translations } from './footer.translations';
 
@Component({
  selector: 'app-root-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  translations = translations;
 
  sendEmail() {
    const mailtoLink = `mailto:LING.AI2@accenture.com?Subject=Contact%20LING.AI%20Support&body=${encodeURIComponent(this.defaultEmailBody())}`;
    window.location.href = mailtoLink;
  }

  defaultEmailBody(): string {
    return `Dear user,\n\n` +
           `Thank you for reaching out to us – this is an email to a monitored mailbox for LING.AI. Please follow the following format for your inquiry to help us solve your request as swiftly as possible.\n\n` +
           `1. Please state the nature of your inquiry (issue with the tool / general information about the product):\n` +
           `2. In case of issue with the tool, please add more details:\n` +
           `\t 2a. What input document type did you use?:\n` +
           `\t 2b. Please describe the problem (e.g. what steps did you take, what issues occurred, what was your expected outcome):\n` +
           `\t 2c. Please add the following as attachments to this email:\n` +
           `\t\t - Screenshots of error messages / issues\n` +
           `\t\t - Input document file\n` +
           `\t\t - Output document file (if applicable)\n\n` +
           `Thank you and kind regards,\n` +
           `The LING.AI team`;
  }

}