import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { apiConfig } from '../constants/apiRoutes';
import {
  TranslationOrchestratorResponse,
  TranslationWebhookResponse,
} from '../interfaces';
import {
  FileTranslationRequest,
  TextTranslationRequest,
} from '../interfaces/TranslationRequest.interface';
import { AuthService } from '../app/modules/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  eventSource;

  constructor(
    private httpClient: HttpClient,
    private readonly authService: AuthService
  ) {}

  public requestTranslation(
    data: TextTranslationRequest | FileTranslationRequest,
    domain?: string, model?: string
  ): Observable<TranslationOrchestratorResponse> {
    const formData = new FormData();

    if (data.type === 'text') {
      formData.append('language', data.language);
      formData.append('text', data.text);
      domain ? formData.append('domain', domain) : null;
      model ? formData.append('model', model) : null;
    } else {
      formData.append('languages', data.languages.join(','));
      data.files.forEach((file) => formData.append('files', file, file.name));
      domain ? formData.append('domain', domain) : null;
      model ? formData.append('model', model) : null;
    }

    return this.httpClient.post<TranslationOrchestratorResponse>(
      `${environment.apiUrl}${apiConfig.orchestrator}`,
      formData,
      {
        headers: this.getAuthHeader(),
      }
    );
  }

  public checkTranslation(url: string): Observable<TranslationWebhookResponse> {
    return this.httpClient.get<TranslationWebhookResponse>(url, {
      headers: this.getAuthHeader(),
    });
  }

  protected getAuthHeader(): HttpHeaders {
    const authData = this.authService.authData.getValue();

    return new HttpHeaders({
      Authorization: `Bearer ${authData.idToken}`,
    });
  }
}
