import { Injectable } from '@angular/core';

@Injectable()
export class SharedLoaderService {
  private amount: number = 0;

  setAmount(amount: number) {
    this.amount = amount
  }

  getAmount(): number {
    return this.amount
  }
}
