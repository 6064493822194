import { NgModule } from '@angular/core';
import { MsalModule, MsalService } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [],
  imports: [
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          authority: environment.auth.authority,
          clientId: environment.auth.clientId,
          redirectUri: environment.auth.redirectUrl,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      { interactionType: InteractionType.Redirect },
      null
    ),
  ],
  providers: [MsalService, AuthService],
  bootstrap: [],
})
export class AuthModule {}
