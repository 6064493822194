import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/services/loader.service';

@Component({
  selector: 'app-root-progress-bar',
  styleUrls: ['./progress-bar.component.scss'],
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent implements OnDestroy, OnInit {
  progress: number | null;

  progressSubscription: Subscription;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.progressSubscription = this.loaderService.loaderProgress.subscribe(
      (value) => {
        if (!value) {
          this.progress = null;
        } else {
          this.progress = (value.current / value.steps) * 100;
        }
      }
    );
  }

  ngOnDestroy() {
    this.progressSubscription.unsubscribe();
  }
}
